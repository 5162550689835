import { useCallback, useMemo } from 'react';
import find from 'lodash/find';

import { GeneralLedgerGeneralLedgerTypes } from '../../../../../../generalLedgers/generalLedgersTypes';
import { FetchGeneralLedgersQueryResponse } from '../../../../../../generalLedgers/queries/fetchGeneralLedgers.query';

import { useCreateProjectInTeamFormContext } from '../../hooks/useCreateProjectInTeamFormContext';
import { useCachedGeneralLedgers } from '../../../../../../generalLedgers/hooks/useCachedGeneralLedgers';

import { SelectBillingInfoModalSubmitCallbackType } from '../../../../../../billingInfos/components/modalButtons/SelectBillingInfoModalButton/hooks/useSelectBillingInfoModalButton/useSelectBillingInfoModalButton';

import { SelectBillingInfoModalButton } from '../../../../../../billingInfos/components/modalButtons/SelectBillingInfoModalButton';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';
import { ConfirmModalButton } from '../../../../../../../helpers/buttons/ConfirmModalButton';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';

import { ProjectsPermissions } from '../../../../../projectsConstants';
import { GeneralLedgerCache } from '../../../../../../generalLedgers/GeneralLedgerCache';

import {
  billingInfosKeys,
  stringsKeys,
  words
} from '../../../../../../../locales/keys';

function CreateProjectInTeamFormButtons() {
  const {
    createProjectInTeamLoading,
    currentUser,
    handleCreateProject,
    resetCreateProjectInTeamForm,
    selectedBillingInfoId,
    watchWithInvoice,
    selectedPaymentMethod,
    selectedTeamNanoId,
    selectedGeneralLedgerId,
    setBillingInfoId,
    setPaymentMethod
  } = useCreateProjectInTeamFormContext();

  const { generalLedgers } = useCachedGeneralLedgers<
    FetchGeneralLedgersQueryResponse[]
  >({
    cacheKey:
      GeneralLedgerCache.companyGeneralLedgersCacheKey(selectedTeamNanoId),
    filters: {
      companyNanoId: { eq: selectedTeamNanoId }
    }
  });

  const selectedGeneralLedgerIsDefault = useMemo<boolean>(() => {
    return currentUser.hasPermissions(
      ProjectsPermissions.CHANGE_CREATE_PROJECT_IN_TEAM_GENERAL_LEDGER_ID
    )
      ? find(generalLedgers, { id: selectedGeneralLedgerId })
          ?.generalLedgerType === GeneralLedgerGeneralLedgerTypes.DEFAULT
      : true;
  }, [currentUser, generalLedgers, selectedGeneralLedgerId]);

  const handleSubmitAfterBillingInfoSelect =
    useCallback<SelectBillingInfoModalSubmitCallbackType>(
      async (billingInfoId, paymentMethod) => {
        setBillingInfoId(billingInfoId);
        setPaymentMethod(paymentMethod);
        await handleCreateProject();
      },
      [handleCreateProject, setBillingInfoId, setPaymentMethod]
    );

  return (
    <div className="flex justify-end gap-4 items-center">
      <CheckPermissions
        action={
          ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_CLEAR_MODAL_BUTTON
        }
      >
        <ConfirmModalButton
          className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 mr-auto"
          i18nText={stringsKeys.areYouSureYouWantToClearAllFormFields}
          i18nButtonText={words.clear}
          i18nSubmitText={words.ok}
          onSubmit={resetCreateProjectInTeamForm}
        />
      </CheckPermissions>

      <CheckPermissions
        action={
          ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_CREATE_PROJECT_BUTTON
        }
      >
        {selectedBillingInfoId || !watchWithInvoice ? (
          <PureButtonHelper
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
            disabled={
              createProjectInTeamLoading || !selectedGeneralLedgerIsDefault
            }
            i18nText={words.create}
            onClick={handleCreateProject}
          />
        ) : (
          <SelectBillingInfoModalButton
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
            companyNanoId={selectedTeamNanoId}
            defaultBillingInfoId={selectedBillingInfoId}
            disabled={
              createProjectInTeamLoading || !selectedGeneralLedgerIsDefault
            }
            i18nButtonText={words.create}
            i18nText={
              billingInfosKeys.addYourBillingAddressSoWeCanCreateAnInvoiceForYourProject
            }
            i18nTitle={billingInfosKeys.singular}
            onSubmit={handleSubmitAfterBillingInfoSelect}
            preferredPaymentMethod={selectedPaymentMethod}
            withCreateForm
            withoutReceiver={
              !currentUser.hasPermissions(
                ProjectsPermissions.READ_CREATE_PROJECT_AND_INVOICE_MODAL_AV_BILLING_INFO_ID
              )
            }
          />
        )}
      </CheckPermissions>
    </div>
  );
}

export default CreateProjectInTeamFormButtons;
