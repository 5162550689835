import React, { useCallback, useEffect, useRef, useState } from 'react';
import cl from 'classnames';
import includes from 'lodash/includes';

import { PathUrl } from '../../../../../types';

import {
  CreateProjectInTeamAuthFormSteps,
  CreateProjectInTeamFormFields
} from './CreateProjectInTeamForm.types';

import { useCreateProjectInTeamFormContext } from './hooks/useCreateProjectInTeamFormContext';

import { CreateProjectInTeamAuthFormFirstStepContent } from './components/content/CreateProjectInTeamAuthFormFirstStepContent';
import { CreateProjectInTeamAuthFormSecondStepContent } from './components/content/CreateProjectInTeamAuthFormSecondStepContent';
import { CreateProjectInTeamAuthFormFooter } from './components/CreateProjectInTeamAuthFormFooter';

import { Form } from '../../../../../helpers/Form';

const FIRST_STEP_FIELDS = [
  CreateProjectInTeamFormFields.NAME,
  CreateProjectInTeamFormFields.IMPLEMENTATION_DATE,
  CreateProjectInTeamFormFields.DESCRIPTION,
  CreateProjectInTeamFormFields.FILE_ATTACHMENT_IDS
];

const CREATE_PROJECT_IN_TEAM_AUTH_FORM = 'create-project-in-team-auth-form';

interface CreateProjectInTeamAuthFormProps {
  skipHref: PathUrl;
  withSkip?: boolean;
}

function CreateProjectInTeamAuthForm({
  skipHref,
  withSkip
}: CreateProjectInTeamAuthFormProps) {
  const {
    control,
    createProjectInTeamLoading,
    currentUser,
    formNanoId,
    handleCreateProject,
    handleCreateProjectWithoutItems,
    registerDescription,
    registerImplementationDate,
    registerName,
    trigger,
    watch,
    validationErrors,
    watchProjectTotal,
    currencyExchangeRate,
    currencyPrefix
  } = useCreateProjectInTeamFormContext();

  const [step, setStep] = useState<CreateProjectInTeamAuthFormSteps>(
    CreateProjectInTeamAuthFormSteps.FIRST
  );

  const nextStepTouchedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!watch || !trigger) {
      return;
    }
    const subscription = watch((value, { name, type }) => {
      if (
        nextStepTouchedRef.current &&
        type === 'change' &&
        includes(FIRST_STEP_FIELDS, name)
      ) {
        trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const handleNext = useCallback<() => void>(async () => {
    nextStepTouchedRef.current = true;

    const isValidFirstStepFields = await trigger(FIRST_STEP_FIELDS);

    if (isValidFirstStepFields) {
      setStep(CreateProjectInTeamAuthFormSteps.SECOND);
    }
  }, [trigger]);

  const handleBack = useCallback<() => void>(() => {
    setStep(CreateProjectInTeamAuthFormSteps.FIRST);
  }, []);

  return (
    <Form
      id={CREATE_PROJECT_IN_TEAM_AUTH_FORM}
      className="flex-1 flex flex-col"
      onSubmit={handleCreateProject}
    >
      <div className="flex-1 py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-4xl py-8 px-4 sm:px-10">
          <CreateProjectInTeamAuthFormFirstStepContent
            className={cl(
              step === CreateProjectInTeamAuthFormSteps.FIRST ? null : 'hidden'
            )}
            control={control}
            createProjectInTeamLoading={createProjectInTeamLoading}
            currentUser={currentUser}
            formNanoId={formNanoId}
            registerDescription={registerDescription}
            registerImplementationDate={registerImplementationDate}
            registerName={registerName}
            validationErrors={validationErrors}
          />

          <CreateProjectInTeamAuthFormSecondStepContent
            className={cl(
              step === CreateProjectInTeamAuthFormSteps.SECOND ? null : 'hidden'
            )}
            currencyExchangeRate={currencyExchangeRate}
            currencyPrefix={currencyPrefix}
            watchProjectTotal={watchProjectTotal}
          />
        </div>
      </div>

      <CreateProjectInTeamAuthFormFooter
        createProjectInTeamLoading={createProjectInTeamLoading}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSkip={handleCreateProjectWithoutItems}
        skipHref={skipHref}
        step={step}
        withSkip={withSkip}
      />
    </Form>
  );
}

export default CreateProjectInTeamAuthForm;
