import { useEffect, useMemo } from 'react';
import find from 'lodash/find';

import {
  FETCH_BILLING_INFOS_QUERY,
  FetchBillingInfosQueryResponse
} from '../../../../../../../../billingInfos/queries/fetchBillingInfos.query';

import { useCreateProjectInTeamFormContext } from '../../../../hooks/useCreateProjectInTeamFormContext';
import { useFinPaginatedBillingInfos } from '../../../../../../../../billingInfos/hooks/useFinPaginatedBillingInfos';
import { usePreviousValue } from '../../../../../../../../../common/hooks/usePreviousValue';

import { FetchBillingInfosBillingInfoType } from '../../../../../../../../billingInfos/hooks/usePaginatedBillingInfos/usePaginatedBillingInfos';

import { BillingInfoCache } from '../../../../../../../../billingInfos/BillingInfoCache';

function useCreateProjectInTeamFormInvoiceDetails() {
  const {
    control,
    selectedBillingInfoId,
    selectedPaymentMethod,
    selectedTeamNanoId,
    setBillingInfoId,
    setNotes,
    createProjectInTeamLoading,
    currentUser,
    defaultTerms,
    defaultNotes,
    registerWithInvoice
  } = useCreateProjectInTeamFormContext();

  const { billingInfos, filterBillingInfos } =
    useFinPaginatedBillingInfos<FetchBillingInfosQueryResponse>({
      cacheKey: BillingInfoCache.teamCacheKey(selectedTeamNanoId),
      initialLimit: 100,
      initialFilters: {
        companyNanoId: { eq: selectedTeamNanoId }
      },
      options: {
        enabled: !!selectedTeamNanoId,
        enabledPlaceholder: !!selectedTeamNanoId,
        withoutPrefetch: true
      },
      query: FETCH_BILLING_INFOS_QUERY
    });

  const prevSelectedTeamNanoId = usePreviousValue(selectedTeamNanoId);

  useEffect(() => {
    if (prevSelectedTeamNanoId !== selectedTeamNanoId) {
      filterBillingInfos({ companyNanoId: { eq: selectedTeamNanoId } });
    }
  }, [prevSelectedTeamNanoId, selectedTeamNanoId, filterBillingInfos]);

  const defaultBillingInfo = useMemo<FetchBillingInfosBillingInfoType>(
    () =>
      find(
        billingInfos,
        (bi) => bi.defaultAt && bi.company?.nanoId === selectedTeamNanoId
      ),
    [billingInfos, selectedTeamNanoId]
  );

  const selectedBillingInfo = useMemo<FetchBillingInfosBillingInfoType>(
    () => find(billingInfos, (bi) => bi.id === selectedBillingInfoId),
    [billingInfos, selectedBillingInfoId]
  );

  useEffect(
    () => setBillingInfoId(defaultBillingInfo?.id),
    [defaultBillingInfo?.id, setBillingInfoId]
  );

  useEffect(
    () => setNotes(selectedBillingInfo?.avBillingInfo?.notes || ''),
    [selectedBillingInfo?.avBillingInfo?.notes, setNotes]
  );

  return {
    control,
    createProjectInTeamLoading,
    currentUser,
    selectedBillingInfo,
    selectedBillingInfoId,
    selectedTeamNanoId,
    setBillingInfoId,
    defaultTerms,
    defaultNotes,
    registerWithInvoice
  };
}

export default useCreateProjectInTeamFormInvoiceDetails;
