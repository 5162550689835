import { useCallback, useMemo } from 'react';

import { MoneyType } from '../../../../../../../../../../../types';
import { CreateProjectInTeamFormDataTaskItem } from '../../../../../../CreateProjectInTeamForm.types';
import { FetchFinItemTypesFilters } from '../../../../../../../../../../itemTypes/itemTypesTypes';
import { ItemTypeIdsChangeCallbackType } from '../../../../../../../../../../itemTypes/helpers/ItemTypeIdsSelectField';

import { useCreateProjectInTeamFormContext } from '../../../../../../hooks/useCreateProjectInTeamFormContext';
import { useConvertCurrencyRates } from '../../../../../../../../../../currencyRates/hooks/useConvertCurrencyRates';

import { prepareItemForConvert } from '../../../../../../../../../../itemTypes/utils/prepareItemForConvert';

import { formsErrors } from '../../../../../../../../../../../locales/keys';

interface CreateProjectInTeamFormItemsTableRowOptions {
  getItem: (itemIndex: number) => CreateProjectInTeamFormDataTaskItem;
  index: number;
  removeItem: (index: number) => void;
  taskIndex: number;
  currencyExchangeRate: MoneyType;
}

function useCreateProjectInTeamFormItemsTableRow({
  getItem,
  index,
  removeItem,
  taskIndex,
  currencyExchangeRate
}: CreateProjectInTeamFormItemsTableRowOptions) {
  const {
    control,
    register,
    selectedTeamNanoId,
    setTaskItemDescription,
    setTaskItemPrice,
    validationErrors: {
      taskItemTypeIdValidationError,
      taskItemQtyValidationError,
      taskItemPriceValidationError
    },
    watchTaskItemSubtotal,
    watchCurrency
  } = useCreateProjectInTeamFormContext();

  const registerItemDescriptionField = register(
    `tasks.${taskIndex}.items.${index}.description`
  );

  const registerItemQuantityField = register(
    `tasks.${taskIndex}.items.${index}.quantity`,
    {
      min: {
        value: 1,
        message: '< 1'
      },
      required: formsErrors.requiredLowerCase,
      valueAsNumber: true
    }
  );

  const registerItemPriceField = register(
    `tasks.${taskIndex}.items.${index}.price`,
    {
      min: {
        value: 0,
        message: '< 0.0'
      },
      required: formsErrors.requiredLowerCase,
      valueAsNumber: true
    }
  );

  const handleRemoveItem = useCallback<() => Promise<void>>(
    async () => removeItem(index),
    [removeItem, index]
  );

  const itemTypesFilters = useMemo<FetchFinItemTypesFilters>(
    () => ({
      companyNanoId: { eq: selectedTeamNanoId }
    }),
    [selectedTeamNanoId]
  );

  const { convertCurrencyCrossExchangeUsd } = useConvertCurrencyRates();

  const handleOnChange = useCallback<ItemTypeIdsChangeCallbackType>(
    (itemType) => {
      console.log('handleOnChange itemType', itemType);

      const preparedItem = prepareItemForConvert(itemType);

      const price =
        convertCurrencyCrossExchangeUsd(
          preparedItem.price,
          preparedItem.currency,
          watchCurrency
        ) || itemType.price;

      setTaskItemDescription(taskIndex, index, itemType.description);

      setTaskItemPrice(taskIndex, index, {
        price,
        viewPrice: itemType.viewPrice || price,
        viewPriceCurrency: itemType.viewPriceCurrency || watchCurrency
      });
    },
    [
      convertCurrencyCrossExchangeUsd,
      index,
      setTaskItemPrice,
      setTaskItemDescription,
      taskIndex,
      watchCurrency
    ]
  );

  return {
    control,
    handleOnChange,
    handleRemoveItem,
    itemTypesFilters,
    registerItemDescriptionField,
    registerItemPriceField,
    registerItemQuantityField,
    selectedItemTypeId: getItem(index)?.itemTypeId,
    selectedTeamNanoId,
    taskItemTypeIdValidationErrorMessage: taskItemTypeIdValidationError(
      taskIndex,
      index
    ),
    taskItemQtyValidationErrorMessage: taskItemQtyValidationError(
      taskIndex,
      index
    ),
    taskItemPriceValidationErrorMessage: taskItemPriceValidationError(
      taskIndex,
      index
    ),
    watchTaskItemSubtotal,
    watchCurrency
  };
}

export default useCreateProjectInTeamFormItemsTableRow;
